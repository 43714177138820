<template>
  <div>
    <el-card :body-style="{ padding: '10px'}">
      <div slot="header">
        <div class="shaixuan">
          <div>
            <el-date-picker
              @change="filterData"
              size="medium"
              v-model="times"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" size="medium" style="margin-left: 10px" @click="filterData">搜索</el-button>
            <el-switch
              v-model="is_province"
              active-text="省份筛选"
              inactive-text="地址筛选">
            </el-switch>
          </div>
          <div>
            <el-button type="success" size="medium" @click="exportExcel">导出</el-button>
          </div>
          
        </div>
      </div>
      <el-table :data="tableData" size="medium" stripe v-loading="loading" @selection-change="handleSelectionChange" :row-class-name="checkStyle" height="730" element-loading-text="拼命加载中" @sort-change="sortChange">
        <el-table-column width="55" type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="return_info"  align="center" label="寄回地址" show-overflow-tooltip min-width="200"></el-table-column>
        <el-table-column prop="back_order_count"  align="center" label="寄回订单数量" sortable="custom" min-width="50"></el-table-column>
        <el-table-column prop="back_order_number"  align="center" label="寄回单量" sortable="custom" min-width="50"></el-table-column>
        <el-table-column prop="back_product_count"  align="center" label="寄回团品件数" sortable="custom" min-width="50">
        </el-table-column>
      </el-table>
      <div class="page">
        <BasePagination :total="total" :now.sync="currentPage" :pageSize="pageSize" @changeSize="changeSize"></BasePagination>
      </div>
    </el-card>
    
  </div>
</template>

<script>
import { getFirthDay, getPreMonthDay } from "@/utils/utils";
import { export_json_to_excel } from "@/plugins/Export2Excel";
export default {
  props: {
    msg: String
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      loading: false,
      is_province: false,

      multipleSelection: [],
      dialogVisible: false,

      times: [],

      sortItem: {}
    }
  },
  computed: {
    red() {
      let flag = this.jine - this.lipeiPrice < 0
      return {
        [this.$style.red]: flag
      }
    }
  },
  watch: {
    currentPage() {
      this.getData()
    },
    is_province() {
      this.getData()
    }
  },
  mounted () {
    this.times = [getFirthDay(), getPreMonthDay()]
    this.getData()
  },
  methods: {
    sortChange(column) {
      console.log(column);
      switch (column.order) {
        case 'descending':
          this.sortItem.order_type = 'desc'
          break;
      case 'ascending':
          this.sortItem.order_type = 'asc'
          break;
      }
      this.sortItem.order_field = column.prop
      this.filterData()
    },
    
    exportExcel() {
      // if(!this.multipleSelection.length)
      //   return this.$message.warning('请至少选中一条数据')  
      const excelHeader = ["寄回地址", "寄回订单数量", "寄回单量", "寄回团品件数"]
      const filterVal = ["return_info", "back_order_count", "back_order_number", "back_product_count"]
      const data = this.formatJson(filterVal, this.tableData);
      const excelName='寄回统计监控数据'
      export_json_to_excel(excelHeader, data, excelName)
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    changeSize(val) {
      console.log(val);
      this.pageSize = val
      this.filterData()
    },
    checkStyle({row}) {
      if(row.isRed) {
        return this.$style.red
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 计算选中
    compute() {},
    filterData() {
      this.currentPage = 1
      this.tableData = []
      this.getData()
    },
    getData() {
      this.loading = true
      this.$http.get('/return_info_area', {
        params: {
          page_number: this.pageSize,
          page: this.currentPage,
          start_date: this.times?.[0],
          end_date: this.times?.[1],
          is_province: this.is_province ? 1 : 0,
          ...this.sortItem
        }
      }).then(res => {
        this.loading = false
        this.tableData = res.data.data
        // .map(o => {
          // if(o.total_claims > o.total_yfx)o.isRed = true
        //   return o
        // })
        this.total = res.data.total
      }).catch(err => {
        console.log(err);
        this.loading = false
      })
    }
  }
}
</script>

<style module>
.red {
  color: red !important;
  font-weight: bold;
}
</style>
<style scoped>
.hello {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.title {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.right {
  /* position: absolute;
  right: 0px;
  top: 0px; */
  text-align: right;
  font-size: 16px;
}
.hello span {
  margin-right: 30px;
}
.page {
  padding-top: 10px;
  text-align: center;
}
.dialog {
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
.shaixuan {
  /* margin: 0 20px; */
  display: flex;
  justify-content: space-between;
}
</style>
<style>

.el-switch {
  margin-left: 10px;
}
</style>
